<script>
  import {
    layoutComputed
  } from "@/state/helpers";
  // import {
  //   SimpleBar
  // } from "simplebar-vue3";

  export default {
    components: {

    },
    data() {
      return {
        settings: {
          minScrollbarLength: 60,
        },
      };
    },
    computed: {
      ...layoutComputed,
      layoutType: {
        get() {
          return this.$store ? this.$store.state.layout.layoutType : {} || {};
        },
      },
    },

    watch: {
      $route: {
        handler: "onRoutechange",
        immediate: true,
        deep: true,
      },
    },

    mounted() {
    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse");

      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation();
          let closestCollapse = collapse.parentElement.closest(".collapse");
          if (closestCollapse) {
            let siblingCollapses =
              closestCollapse.querySelectorAll(".collapse");
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                siblingCollapse.classList.remove("show");
              }
            });
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = [];
              let sibling = elem.parentNode.firstChild;
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
              }
              return siblings;
            };
            let siblings = getSiblings(collapse.parentElement);
            siblings.forEach((item) => {
              if (item.childNodes.length > 2)
                item.firstElementChild.setAttribute("aria-expanded", "false");
              let ids = item.querySelectorAll("*[id]");
              ids.forEach((item1) => {
                item1.classList.remove("show");
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a");

                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false");
                  });
                }
              });
            });
          }
        });

        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation();
          let childCollapses = collapse.querySelectorAll(".collapse");
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse;
            childCollapseInstance.style.display = "none";
          });
        });
      });
    }
  },

  methods: {
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if (this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
      }
    },

    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document.querySelector("#navbar-nav").querySelector('[href="' + ele + '"]');
          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add("active");
              parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
              if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                  parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add("active");
                const grandparent = parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.parentElement.closest(".collapse");
                if(grandparent && grandparent.previousElementSibling){
                  grandparent.previousElementSibling.classList.add("active");
                  grandparent.classList.add("show");
                }
              }
            }
          }
        }
      }, 0);
    },
  },
  };
</script>

<template>
  <div class="container-fluid">
    <div>
      <ul class="navbar-nav h-100" id="navbar-nav">
<!--        <li class="menu-title">-->
<!--          <span data-key="t-menu"> {{ $t("t-menu") }}</span>-->
<!--        </li>-->
        <li class="nav-item">
          <router-link :to="{ name: 'admin-dashboard' }" class="nav-link menu-link" role="button"
            aria-expanded="false" aria-controls="sidebarDashboards">
            <i class="las la-tachometer-alt"></i>
            <span data-key="Dashboard">Dashboard</span>
          </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarProduct" data-bs-toggle="collapse" role="button"
             aria-expanded="false" aria-controls="sidebarProduct">
            <i class="bx bx-shopping-bag"></i>
            <span data-key="Utilisateurs">Produits</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarProduct">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link :to="{ name: 'admin-products' }" class="nav-link" data-key="List">
                  Liste
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarAuth" data-bs-toggle="collapse" role="button"
             aria-expanded="false" aria-controls="sidebarAuth">
            <i class="las la-users"></i>
            <span data-key="Utilisateurs">Utilisateurs</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarAuth">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link :to="{ name: 'admin-coordinator' }" class="nav-link" data-key="coordinators">
                  Coordinateurs
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'admin-supervisors' }" class="nav-link" data-key="Superviseurs">
                  Superviseurs
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'admin-commercials' }" class="nav-link" data-key="Commerciaux">
                  Commerciaux
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'admin-members' }" class="nav-link" data-key="Adhérents">
                  Adhérents
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'admin-account' }" class="nav-link" data-key="Comptes">
                  Comptes
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'admin-search' }" class="nav-link menu-link" role="button"
                       aria-expanded="false" aria-controls="sidebarMessage">
            <i class="bx bx-search-alt"></i>
            <span data-key="Dashboard">Recherche</span>
          </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarFunding" data-bs-toggle="collapse" role="button"
             aria-expanded="false" aria-controls="sidebarFunding">
            <i class=" las la-money-bill"></i>
            <span data-key="funding">Financements</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarFunding">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link :to="{ name: 'admin-funding-request' }" class="nav-link" data-key="contribution">
                  Demandes
                </router-link>
                <router-link :to="{ name: 'admin-funding-granted' }" class="nav-link" data-key="subscription">
                  Accordés
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarApps" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarApps">
            <i class="las la-dollar-sign"></i>
            <span data-key="Transactions">Transactions</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarApps">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link :to="{ name: 'admin-transactions-subscription' }" class="nav-link" data-key="contribution">
                  Souscription
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'admin-transactions-contribution' }" class="nav-link" data-key="subscription">
                  Contribution
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'admin-transaction-funding-refund' }" class="nav-link" data-key="funding-refund">
                  Remboursement Financement
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'admin-transactions-failed'}" class="nav-link" data-key="failed">
                  Echecs
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link menu-link" href="#locationSideBar" data-bs-toggle="collapse" role="button"
             aria-expanded="false" aria-controls="locationSideBar">
            <i class="las la-map-marker"></i>
            <span data-key="funding">Localisation</span>
          </a>
          <div class="collapse menu-dropdown" id="locationSideBar">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link :to="{ name: 'admin-towns' }" class="nav-link" data-key="contribution">
                  Villes
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'admin-notification' }" class="nav-link menu-link" role="button"
               aria-expanded="false" aria-controls="sidebarMessage">
            <i class="bx bx-message-dots"></i>
            <span data-key="Dashboard">Message</span>
          </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarParams" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarApps">
            <i class="las la-cog"></i>
            <span data-key="Paramètres">Paramètres</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarParams">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link :to="{ name: 'admin-application-parameter' }" class="nav-link" data-key="Application">
                  Application
                </router-link>
              </li>
              <li class="nav-item">
                <a class="nav-link menu-link" href="#sidebarMultilevel" data-bs-toggle="collapse" role="button"
                  aria-expanded="false" aria-controls="sidebarMultilevel">
                  <span data-key="t-multi-level">Recherche</span>
                </a>
                <div class="collapse menu-dropdown" id="sidebarMultilevel">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link :to="{ name: 'admin-parameters-search-attribute' }" class="nav-link" data-key="attributes">
                        Attributs
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link :to="{ name: 'admin-parameters-search-criteria' }" class="nav-link" data-key="attributes">
                        Critères
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- Sidebar -->
</template>